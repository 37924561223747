
import { defineComponent, onMounted } from "vue";
import WorkdayResume from "@/components/workday/WorkdayResume.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import ApiService from "@/core/services/ApiService";
export default defineComponent({
  name: "workday-list",
  components: {
    WorkdayResume,
  },
  data() {
    return {
      supervisorId: "",
      workDay: null,
    };
  },
  methods: {
    startDay() {
      ApiService.post("workdays", {
        supervisorId: this.supervisorId,
      }).then(() => {
        this.retrieveData();
      });
    },
    retrieveData() {
      ApiService.get("workdays", this.$route.params.id.toString()).then(
        (response) => {
          this.workDay = response.data.data;
        }
      );
    },
  },
  mounted() {
    setCurrentPageBreadcrumbs("Parte Diario", ["Listado"]);
    MenuComponent.reinitialization();
    this.retrieveData();
  },
});
